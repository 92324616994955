const PATTERN = /^(\d+)-(\d+)$/;

export default class DefaultPk {
  pk: number;

  cpk: number;

  constructor(id: string) {
    const match = PATTERN.exec(id);
    if (!match) {
      throw new Error(`Invalid ID: ${id}`);
    }
    this.cpk = Number(match[1]);
    this.pk = Number(match[2]);
  }

  equals(other: DefaultPk) {
    return this.pk === other.pk && this.cpk === other.cpk;
  }

  toString() {
    return `${this.cpk}-${this.pk}`;
  }
}
