import CartResource from '@/store/cart-resource';
import CartResourceLock, { RESOURCE } from '@/store/cart-resource-lock';
import CartRequest from '@/models/entities/cart/cart-request';

export default class OrderTypeCartResource {
  private cartResource: CartResource;

  constructor(cartResourceLock: CartResourceLock, orderTypeId: string) {
    this.cartResource = new CartResource(
      cartResourceLock,
      RESOURCE.ORDER_TYPE_CART(orderTypeId),
      `api/ot/${orderTypeId}/cart`,
    );
  }

  setHttpRequestStartedHook(httpRequestStartedHook: () => void) {
    this.cartResource.setHttpRequestStartedHook(httpRequestStartedHook);
  }

  setClientCartGetter(clientCartGetter: () => Promise<CartRequest>) {
    this.cartResource.setClientCartGetter(clientCartGetter);
  }

  async get() {
    return this.cartResource.get();
  }

  async post(delayMs: number) {
    return this.cartResource.post(delayMs);
  }

  getLastSyncTime() {
    return this.cartResource.getLastSyncTime();
  }

  setLastSyncTime(lastSyncTime: string) {
    this.cartResource.setLastSyncTime(lastSyncTime);
  }
}
