import CartResponse from '@/models/entities/cart/cart-response';
import ApiResponse from '@/models/entities/api/api-response';

export default class CartsResponse extends ApiResponse {
  carts: Array<CartResponse>;

  constructor(source: unknown) {
    // GET carts response is just a list, not an object. We won't have code or message.
    // The POST response will have them, but we don't care about that for now. If that changes,
    // we should make the back end consistent.
    super({ code: 0, message: 'Success' });
    if (Array.isArray(source)) {
      this.carts = [];
      for (const cartResponse of source) {
        try {
          this.carts.push(new CartResponse(cartResponse));
        } catch {
          throw new Error('Invalid CartResponse in CartsResponse');
        }
      }
    }
  }
}
