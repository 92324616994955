export const REQUEST_TYPE = {
  GET: 'GET',
  POST: 'POST',
} as const;

type ObjectValues<T> = T[keyof T];

export type RequestType = ObjectValues<typeof REQUEST_TYPE>;

export type RequestTask = {
  id: number;
  type: RequestType;
  allowedToStart: Date;
  resolved: Promise<unknown>;
  resolve: Function;
};

export type TaskReference = {
  id: number;
  type: RequestType;
};

export type StartAllowance = {
  isAllowedToStart: boolean;
  delayMs: number;
};

export default class ProtectedApiStateRequestTaskList {
  private list: Array<RequestTask>;

  private latch: boolean;

  constructor() {
    this.clear();
  }

  add(taskId: number, type: RequestType, delayMs: number): TaskReference {
    const allowedToStart = new Date(Date.now() + delayMs);
    let resolve: Function;
    const promise = new Promise(r => {
      resolve = r;
    });
    this.list.push({ id: taskId, type, allowedToStart, resolved: promise, resolve });
    return { id: taskId, type };
  }

  remove(task: TaskReference) {
    const cartTask = this.list.find(t => t.id === task.id);
    if (!cartTask) {
      return;
    }
    cartTask.resolve();
  }

  isAllowedToStart(): StartAllowance {
    if (this.latch || this.list.length === 0) {
      return { isAllowedToStart: true, delayMs: 0 };
    }
    const nowEpochMs = new Date().getTime();
    const maxAllowedToStartEpochMs = Math.max(...this.list.map(t => t.allowedToStart.getTime()));
    if (nowEpochMs >= maxAllowedToStartEpochMs) {
      this.latch = true;
      return { isAllowedToStart: true, delayMs: 0 };
    }
    return { isAllowedToStart: false, delayMs: maxAllowedToStartEpochMs - nowEpochMs };
  }

  mostRelevantTask() {
    // If there is a POST task in the list, return the most recent one.
    const onlyPostTasks = this.list.filter(t => t.type === REQUEST_TYPE.POST);
    if (onlyPostTasks.length) {
      return onlyPostTasks[onlyPostTasks.length - 1];
    }
    // If there are only GET tasks in the list, return the first one.
    if (this.list.length) {
      return this.list[0];
    }
    return null;
  }

  async waitForAllToFinish() {
    for (const task of this.list) {
      // eslint-disable-next-line no-await-in-loop
      await task.resolved;
    }
  }

  clear() {
    this.list = [];
    this.latch = false;
  }
}
