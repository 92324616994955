import { validateStringProperty } from '@/models/entities/type-utils';
import ApiResponse from '@/models/entities/api/api-response';

/**
 * For now, these types are just the types we get from the server. We should switch to using the real types
 * (DefaultPk, BigNumber) as soon as we can justify it.
 */
export default class CartResponse extends ApiResponse {
  id: string;

  version: number;

  subTotal: number;

  rawSubTotal: number;

  deliveryTotal: number;

  shippingTotal: number;

  taxTotal: number;

  tipTotal: number;

  roundUpTotal: number;

  grandTotal: number;

  dueTotal: number;

  tenderedTotal: number;

  ebtSnapEligibleTotal: number;

  ebtTanfEligibleTotal: number;

  payLater: boolean;

  instructions: string;

  lineItems: Array<any>;

  giftCardItems: Array<any>;

  coupons: Array<any>;

  isAddedCouponValid: boolean;

  deferrableCombos: Array<any>;

  checkout: any;

  inStore: boolean;

  orderType?: string;

  online: boolean;

  lastSyncTime?: string;

  constructor(source: unknown) {
    super(source);
    this.id = (source as any).id;
    this.version = (source as any).version;
    this.subTotal = (source as any).subTotal;
    this.rawSubTotal = (source as any).rawSubTotal;
    this.deliveryTotal = (source as any).deliveryTotal;
    this.shippingTotal = (source as any).shippingTotal;
    this.taxTotal = (source as any).taxTotal;
    this.tipTotal = (source as any).tipTotal;
    this.roundUpTotal = (source as any).roundUpTotal;
    this.grandTotal = (source as any).grandTotal;
    this.dueTotal = (source as any).dueTotal;
    this.tenderedTotal = (source as any).tenderedTotal;
    this.ebtSnapEligibleTotal = (source as any).ebtSnapEligibleTotal;
    this.ebtTanfEligibleTotal = (source as any).ebtTanfEligibleTotal;
    this.payLater = (source as any).payLater;
    this.instructions = (source as any).instructions;
    this.lineItems = (source as any).lineItems;
    this.giftCardItems = (source as any).giftCardItems;
    this.coupons = (source as any).coupons;
    this.isAddedCouponValid = (source as any).isAddedCouponValid;
    this.deferrableCombos = (source as any).deferrableCombos;
    this.checkout = (source as any).checkout;
    this.inStore = (source as any).inStore;
    this.orderType = (source as any).orderType;
    this.online = (source as any).online;
    this.lastSyncTime = validateStringProperty(source, 'lastSyncTime', true);
  }
}
