import { parseISO } from 'date-fns';
import BigNumber from 'bignumber.js';

export function validateStringProperty(
  object: unknown,
  propertyName: string,
  nullTolerated: boolean,
): string {
  const property = object[propertyName];
  if ((property === null || property === undefined) && nullTolerated) {
    return undefined;
  }
  if (property === undefined || property === null || typeof property !== 'string') {
    throw new Error(`Missing parameter "${propertyName}"`);
  }
  return property;
}

export function validateNumberProperty(
  object: unknown,
  propertyName: string,
  nullTolerated: boolean,
) {
  const property = object[propertyName];
  if ((property === null || property === undefined) && nullTolerated) {
    return undefined;
  }
  if (property === undefined || property === null || typeof property !== 'number') {
    throw new Error(`Missing or invalid parameter "${propertyName}"`);
  }
  return property;
}

export function validateDateProperty(
  object: unknown,
  propertyName: string,
  nullTolerated: boolean,
): Date {
  let date;
  const property = object[propertyName];
  if ((property === null || property === undefined) && nullTolerated) {
    return undefined;
  }
  if (Object.prototype.toString.call(property) === '[object Date]') {
    date = property;
  } else {
    date = parseISO(property);
  }
  if (date instanceof Error || Number.isNaN(date.getTime())) {
    throw new Error(`Missing parameter "${propertyName}"`);
  }
  return date;
}

export function validateBigNumberProperty(
  object: unknown,
  propertyName: string,
  nullTolerated: boolean,
): BigNumber {
  const property = object[propertyName];
  if ((property === null || property === undefined) && nullTolerated) {
    return undefined;
  }
  const bigNumber = new BigNumber(property);
  if (property === null || bigNumber.isNaN() || bigNumber instanceof Error) {
    throw new Error(`Missing parameter "${propertyName}"`);
  }
  return bigNumber;
}

export function validateBooleanProperty(object: unknown, propertyName: string): boolean {
  const property = object[propertyName];
  if (typeof property === 'boolean') {
    return property;
  }
  throw new Error(`Missing parameter "${propertyName}"`);
}

export function validateObjectProperty(
  object: unknown,
  propertyName: string,
  nullTolerated: boolean,
): object {
  const property = object[propertyName];
  if ((property === null || property === undefined) && nullTolerated) {
    return undefined;
  }
  if (property !== null && property !== undefined && typeof property === 'object') {
    return property;
  }
  throw new Error(`Missing parameter "${propertyName}"`);
}

export function validateArrayProperty<T>(
  object: unknown,
  propertyName: string,
  constructor: (element: unknown) => T,
): Array<T> {
  const property = object[propertyName];
  if (property === null || property === undefined) {
    throw new Error(`Missing parameter "${propertyName}"`);
  }
  if (!Array.isArray(property)) {
    throw new Error(`Parameter "${propertyName}" is not an array`);
  }
  const array: Array<T> = [];
  for (const element of property) {
    array.push(constructor(element));
  }
  return array;
}
