<template>
  <svg
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 496 498"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6598 225.242C52.2598 247.142 91.7598 244.342 112.96 218.842C117.86 212.942 121.26 206.442 123.46 199.542L346.26 183.442C351.86 183.042 355.76 178.342 355.06 172.942C352.16 151.342 331.66 135.042 309.36 136.642L198.36 144.642L194.26 114.442C200.76 111.042 206.56 106.342 211.46 100.442C232.66 75.0422 227.46 36.6422 199.86 14.7422C172.26 -7.15782 132.76 -4.35782 111.56 21.1422C90.3598 46.5422 95.5598 84.9422 123.16 106.842C129.56 111.942 136.56 115.642 143.76 118.142L147.86 148.342L116.76 150.542C112.86 144.042 107.66 138.142 101.26 133.142C73.6598 111.242 34.1598 114.042 12.9598 139.542C-8.14021 165.042 -2.94021 203.342 24.6598 225.242ZM145.16 80.6422C133.36 71.2422 131.06 54.8422 140.16 43.9422C149.26 33.0422 166.16 31.8422 177.96 41.2422C189.76 50.6422 192.06 67.0422 182.96 77.9422C173.96 88.8422 156.96 90.0422 145.16 80.6422ZM41.5598 162.242C50.6598 151.342 67.5598 150.142 79.3598 159.542C91.1598 168.942 93.4598 185.342 84.3598 196.242C75.2598 207.142 58.3598 208.342 46.5598 198.942C34.7598 189.542 32.5598 173.142 41.5598 162.242Z"
      class="wc-coupon-scissors-icon"
    />
    <path
      d="M490.26 468.743L456.66 443.043L489.86 416.943C497.26 411.143 497.16 402.643 489.76 396.943L456.16 371.243L489.36 345.143C496.76 339.343 496.66 330.843 489.26 325.143L455.66 299.443L488.86 273.343C494.76 268.743 496.06 262.343 492.26 256.943C488.46 251.543 480.26 248.043 471.16 248.143L212.66 249.843L207.46 211.143L157.06 214.743L161.86 250.143L23.16 251.043C14.06 251.143 5.86002 254.643 2.16002 260.143C-1.53998 265.643 -0.13998 272.043 5.76002 276.543L39.36 302.243L6.16002 328.343C-1.23998 334.143 -1.13998 342.643 6.26002 348.343L39.86 374.043L6.66002 400.143C-0.739981 405.943 -0.63998 414.443 6.76002 420.143L40.36 445.843L7.16002 471.943C1.26002 476.543 -0.0399802 482.943 3.76002 488.343C7.56002 493.743 15.76 497.243 24.86 497.143L472.96 494.243C482.06 494.143 490.26 490.643 493.96 485.143C497.56 479.643 496.16 473.243 490.26 468.743ZM155.76 445.043C147.26 445.143 140.26 440.543 140.26 434.843L139.46 311.743C139.46 306.143 146.36 301.443 154.86 301.343C163.36 301.243 170.36 305.843 170.36 311.543L171.16 434.643C171.16 440.243 164.26 444.943 155.76 445.043ZM248.46 444.443C239.96 444.543 232.96 439.943 232.96 434.243L232.16 311.143C232.16 305.543 239.06 300.843 247.56 300.743C256.06 300.643 263.06 305.243 263.06 310.943L263.86 434.043C263.86 439.643 256.96 444.343 248.46 444.443ZM341.16 443.843C332.66 443.943 325.66 439.343 325.66 433.643L324.86 310.543C324.86 304.943 331.76 300.243 340.26 300.143C348.76 300.043 355.76 304.643 355.76 310.343L356.56 433.443C356.56 439.043 349.66 443.743 341.16 443.843Z"
      class="wc-coupon-scissors-icon"
    />
  </svg>
</template>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-scissors-icon {
  fill: var(--primary-contrast, get-color-contrast('primary'));
}
</style>
