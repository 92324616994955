
import WCImage from '@/components/WCImage/WCImage.vue';
import WCCouponClipButton from '@/components/WCCouponClipButton/WCCouponClipButton.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import { defineComponent } from 'vue';
import CouponMixin from '../../mixins/CouponMixin';

export default defineComponent({
  mixins: [ModalMixin, CouponMixin],
  components: {
    WCImage,
    WCCouponClipButton,
  },
  methods: {
    handleModalSwap() {
      this.close();
      this.openMoreInfoModal();
    },
  },
});
