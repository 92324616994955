import ProtectedApiActionResource from '@/store/protected-api-action-resource';
import PaymentResponse from '@/models/entities/checkout/payment-response';
import CartResourceLock, { RESOURCE } from '@/store/cart-resource-lock';
import ApiResult from '@/models/entities/api/api-result';
import { getApiErrorFromResponse, makeApiError } from '@/models/entities/api/api-error';
import { RESPONSE_CODE } from '@/constants/ApiConstants';
import axios from 'axios';

export default class ConfirmResource {
  private readonly url: string;

  private readonly protectedApiActionResource: ProtectedApiActionResource<PaymentResponse>;

  constructor(resourceLock: CartResourceLock) {
    this.url = 'api/cart/confirm';
    this.protectedApiActionResource = new ProtectedApiActionResource<PaymentResponse>(
      RESOURCE.CONFIRM,
      resourceLock,
      async () => {
        return this.confirm();
      },
    );
  }

  async post() {
    return this.protectedApiActionResource.post();
  }

  private async confirm() {
    let axiosResponse: any;
    let result: ApiResult<PaymentResponse>;
    try {
      axiosResponse = await axios.post(this.url, {});
    } catch (error) {
      console.error(error);
      return getApiErrorFromResponse(error);
    }

    try {
      result = new PaymentResponse(axiosResponse.data);
    } catch (error) {
      console.error(error);
      result = makeApiError(RESPONSE_CODE.CODE_GENERIC_ERROR, 'Server returned incompatible data');
    }
    return result;
  }
}
