import ActiveCartResource from '@/store/active-cart-resource';
import CartResourceLock from '@/store/cart-resource-lock';
import CartRequest from '@/models/entities/cart/cart-request';
import AllCartsResource from '@/store/all-carts-resource';
import ConfirmResource from '@/store/confirm-resource';
import OrderTypeCartResourceMap from '@/store/OrderTypeCartResourceMap';
import { isResponse } from '@/models/entities/api/api-result';

export class WebCartApi {
  private static instance: WebCartApi;

  private readonly cartResourceLock: CartResourceLock;

  private readonly activeCartResource: ActiveCartResource;

  private readonly orderTypeCartResourceMap: OrderTypeCartResourceMap;

  private readonly allCartsResource: AllCartsResource;

  private readonly confirmResource: ConfirmResource;

  constructor() {
    if (WebCartApi.instance) {
      return WebCartApi.instance;
    }
    this.cartResourceLock = new CartResourceLock();
    this.activeCartResource = new ActiveCartResource(this.cartResourceLock);
    this.orderTypeCartResourceMap = new OrderTypeCartResourceMap(this.cartResourceLock);
    this.allCartsResource = new AllCartsResource(
      this.cartResourceLock,
      this.activeCartResource,
      this.orderTypeCartResourceMap,
    );
    this.confirmResource = new ConfirmResource(this.cartResourceLock);
    WebCartApi.instance = this;
  }

  setHttpRequestStartedHook(loadingHook: () => void) {
    this.activeCartResource.setHttpRequestStartedHook(loadingHook);
    for (const orderTypeCartResource of this.orderTypeCartResourceMap.getAll()) {
      orderTypeCartResource.setHttpRequestStartedHook(loadingHook);
    }
  }

  setActiveCartGetter(clientCartGetter: () => Promise<CartRequest>) {
    this.activeCartResource.setClientCartGetter(clientCartGetter);
  }

  async getActiveCart() {
    return this.activeCartResource.get();
  }

  async syncActiveCart(delayMs: number) {
    return this.activeCartResource.post(delayMs);
  }

  setOrderTypeCartGetter(orderTypeId: string, clientCartGetter: () => Promise<CartRequest>) {
    this.orderTypeCartResourceMap.get(orderTypeId).setClientCartGetter(clientCartGetter);
  }

  async getOrderTypeCart(orderTypeId: string) {
    return this.orderTypeCartResourceMap.get(orderTypeId).get();
  }

  async syncOrderTypeCart(orderTypeId: string, delayMs: number) {
    return this.orderTypeCartResourceMap.get(orderTypeId).post(delayMs);
  }

  async getAllCarts() {
    return this.allCartsResource.get();
  }

  changeActiveCartOrderType(orderTypeId: string) {
    const orderTypeCartResource = this.orderTypeCartResourceMap.get(orderTypeId);
    this.activeCartResource.setLastSyncTime(orderTypeCartResource.getLastSyncTime());
    this.activeCartResource.setOrderTypeId(orderTypeId);
  }

  async confirm() {
    const result = await this.confirmResource.post();
    if (isResponse(result)) {
      this.activeCartResource.setLastSyncTime(null);
      this.orderTypeCartResourceMap
        .get(this.activeCartResource.getOrderTypeId())
        .setLastSyncTime(null);
    }
    return result;
  }
}

const webCartApi = new WebCartApi();
export default webCartApi;
