<template>
  <WCModal @dismiss="isLoading ? null : dismiss('Dismissed')">
    <WCModalHeader class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <h1 class="mb-1">{{ $t('relatedCoupons') }}</h1>
        <div class="mb-0 font-size-md">{{ item.name }}</div>
      </div>

      <button
        @click="dismiss('Dismissed')"
        class="btn btn-link underline-link-from-center"
        data-testid="closeModalButton"
      >
        {{ $t('close') }}
      </button>
    </WCModalHeader>

    <!-- START: MODAL Body -->
    <WCModalBody class="coupon-detail-body">
      <div role="list">
        <WCAvailableCoupon
          v-for="coupon in item.coupons"
          :key="coupon.id"
          :coupon="coupon"
          :dismiss="closeDialog"
          :close="closeDialog"
          role="listitem"
        />
      </div>
    </WCModalBody>
    <!-- END: MODAL Body -->
  </WCModal>
</template>

<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCAvailableCoupon from '@/modules/coupons/components/WCAvailableCoupon/WCAvailableCoupon.vue';
import CouponMixin from '../../mixins/CouponMixin';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  mixins: [ModalMixin, CouponMixin],
  components: {
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCAvailableCoupon,
  },
  created() {
    this.fetchCustomerCoupons();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.dismiss('Dismissed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
@include media-breakpoint-down(sm) {
  .save-btn {
    width: 100%;
  }
}

.modal-body {
  .coupon-detail-body {
    overflow-y: scroll;
  }
}
</style>
