import CartResource from '@/store/cart-resource';
import CartResourceLock, { RESOURCE } from '@/store/cart-resource-lock';
import CartRequest from '@/models/entities/cart/cart-request';

export default class ActiveCartResource {
  private cartResource: CartResource;

  constructor(cartResourceLock: CartResourceLock) {
    this.cartResource = new CartResource(cartResourceLock, RESOURCE.ACTIVE_CART, 'api/cart');
  }

  setHttpRequestStartedHook(httpRequestStartedHook: () => void) {
    this.cartResource.setHttpRequestStartedHook(httpRequestStartedHook);
  }

  setClientCartGetter(clientCartGetter: () => Promise<CartRequest>) {
    this.cartResource.setClientCartGetter(clientCartGetter);
  }

  async get() {
    return this.cartResource.get();
  }

  async post(delayMs: number) {
    return this.cartResource.post(delayMs);
  }

  setLastSyncTime(lastSyncTime: string) {
    this.cartResource.setLastSyncTime(lastSyncTime);
  }

  getOrderTypeId() {
    return this.cartResource.getOrderTypeId();
  }

  setOrderTypeId(orderTypeId: string) {
    this.cartResource.setOrderTypeId(orderTypeId);
  }
}
