import ApiResponse from '@/models/entities/api/api-response';

export default class PaymentResponse extends ApiResponse {
  complete: boolean;

  goodbyeData: any;

  constructor(source: unknown) {
    super(source);
    this.complete = (source as any).complete;
    this.goodbyeData = (source as any).goodbyeData;
  }
}
