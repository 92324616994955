import { validateNumberProperty, validateStringProperty } from '@/models/entities/type-utils';
import { AxiosError } from 'axios';
import { RESPONSE_CODE } from '@/constants/ApiConstants';

export default class ApiError {
  type: 'error';

  code: number;

  message: string;

  constructor(source: unknown) {
    this.type = 'error';
    this.code = validateNumberProperty(source, 'code', false);
    this.message = validateStringProperty(source, 'message', false);
  }
}

export function makeApiError(code: number, message: string) {
  return new ApiError({ code, message });
}

export function getApiErrorFromResponse(error: AxiosError) {
  try {
    return new ApiError(error.response?.data);
  } catch (parseError) {
    console.error(parseError);
    return makeApiError(RESPONSE_CODE.CODE_GENERIC_ERROR, 'An unknown error occurred');
  }
}
