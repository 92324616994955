import ProtectedApiStateResource from '@/store/protected-api-state-resource';
import ApiResult from '@/models/entities/api/api-result';
import CartResourceLock, { RESOURCE } from '@/store/cart-resource-lock';
import { getApiErrorFromResponse, makeApiError } from '@/models/entities/api/api-error';
import { RESPONSE_CODE } from '@/constants/ApiConstants';
import ActiveCartResource from '@/store/active-cart-resource';
import DefaultPk from '@/models/entities/default-pk';
import CartsResponse from '@/models/entities/cart/carts-response';
import axios from 'axios';
import ApiAlreadyHandled from '@/models/entities/api/api-already-handled';
import OrderTypeCartResourceMap from '@/store/OrderTypeCartResourceMap';

export default class AllCartsResource {
  private protectedApiStateResource: ProtectedApiStateResource<CartsResponse>;

  private readonly url: string;

  private activeCartResource: ActiveCartResource;

  private orderTypeCartResourceMap: OrderTypeCartResourceMap;

  constructor(
    cartResourceLock: CartResourceLock,
    activeCartResource: ActiveCartResource,
    orderTypeCartResourceMap: OrderTypeCartResourceMap,
  ) {
    const url = 'api/ot/carts';
    this.protectedApiStateResource = new ProtectedApiStateResource<CartsResponse>(
      RESOURCE.ALL_CARTS,
      cartResourceLock,
      async () => {
        return this.getCart();
      },
      async () => {
        return new ApiAlreadyHandled();
      },
    );
    this.url = url;
    this.activeCartResource = activeCartResource;
    this.orderTypeCartResourceMap = orderTypeCartResourceMap;
  }

  async get() {
    return this.protectedApiStateResource.get();
  }

  private async getCart() {
    let axiosResponse: any;
    let result: ApiResult<CartsResponse>;
    try {
      axiosResponse = await axios.get(this.url);
    } catch (error) {
      console.error(error);
      return getApiErrorFromResponse(error);
    }

    try {
      result = new CartsResponse(axiosResponse.data);
    } catch (error) {
      console.error(error);
      return makeApiError(RESPONSE_CODE.CODE_GENERIC_ERROR, 'Server returned incompatible data');
    }

    for (const cart of result.carts) {
      this.orderTypeCartResourceMap.get(cart.orderType).setLastSyncTime(cart.lastSyncTime);
      const activeOrderTypeId = this.getActiveOrderTypeId();
      if (activeOrderTypeId && activeOrderTypeId.equals(new DefaultPk(cart.orderType))) {
        this.activeCartResource.setLastSyncTime(cart.lastSyncTime);
      }
    }

    return result;
  }

  private getActiveOrderTypeId() {
    try {
      return new DefaultPk(this.activeCartResource.getOrderTypeId());
    } catch (error) {
      console.debug(error);
      return null;
    }
  }
}
