<template>
  <div class="wc-cart-drawer shadow bg-white border d-flex flex-column">
    <div>
      <div class="d-flex justify-content-between align-items-center pt-3 px-3">
        <div class="cart-header">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="font-size-xl mb-0 mr-3">{{ $t('shoppingCart') }}</h3>
              <!-- Mobile view only - show close button navigating to previous page -->
              <button
                class="unstyled-btn d-block d-lg-none"
                :aria-label="$t('goBack')"
                @click="$router.go(-1)"
              >
                <font-awesome-icon size="lg" icon="xmark" />
              </button>
            </div>

            <!-- non-MOT Cart Drawer header-->
            <div v-if="!hasCarts">
              <WCCartDrawerEbtBalance
                :walletEbtTokens="ebtTokens"
                :ebtPaymentMethod="ebtPaymentMethod"
                :loadingWallet="loadingWallet"
              />
              <div class="d-flex justify-content-between align-items-center pt-3">
                <button
                  class="btn btn-outline-primary mr-1 w-100 align-self-stretch"
                  :class="{ 'wc-mobile-font': isMobile }"
                  :aria-label="$t('saveAllItemsForLater')"
                  v-if="isFetched && hasLineItems && !isClearAllItemsSync && !isGuest"
                  @click="openSaveAllItesForLaterModal($refs.saveAllItesForLaterLBtnRef)"
                  ref="saveAllItesForLaterLBtnRef"
                >
                  <font-awesome-icon icon="bookmark" />
                  {{ $t('saveAllItemsForLater') }}
                </button>
                <button
                  class="btn btn-outline-primary ml-1 w-100 align-self-stretch"
                  :class="{ 'wc-mobile-font': isMobile }"
                  :aria-label="$t('clearCart')"
                  v-if="isFetched && (hasLineItems || isGiftCard)"
                  @click="openClearCartModal($refs.clearCartLBtnRef)"
                  ref="clearCartLBtnRef"
                >
                  <font-awesome-icon icon="cart-xmark" />
                  {{ $t('clearCart') }}
                </button>
              </div>
            </div>
            <!-- MOT Cart Drawer header -->
            <div v-else>
              <WCCartDrawerEbtBalance
                :walletEbtTokens="ebtTokens"
                :ebtPaymentMethod="ebtPaymentMethod"
                :loadingWallet="loadingWallet"
              />
              <div>
                <div class="d-flex justify-content-between align-items-center pt-3">
                  <button
                    class="btn btn-outline-secondary wc-remove-hover mr-1 w-100 align-self-stretch"
                    :class="{ 'wc-mobile-font': isMobile }"
                    :aria-label="$t('subtotalOfAllCarts')"
                    v-if="isFetched && (hasLineItems || isGiftCard)"
                  >
                    <span class="text-dark"
                      >{{ $t('subtotalOfAllCarts') }}:
                      <span class="font-weight-bold">{{
                        $filters.currency(cartTotalDisplay)
                      }}</span></span
                    >
                  </button>
                  <button
                    class="btn btn-outline-primary ml-1 w-100 align-self-stretch"
                    :class="{ 'wc-mobile-font': isMobile }"
                    :aria-label="$t('clearCarts')"
                    v-if="isFetched && (hasLineItems || isGiftCard) && !isScanAndGoMode"
                    @click="
                      clearAllCarts();
                      openClearCartModal($refs.clearCartLBtnRef);
                    "
                    ref="clearCartLBtnRef"
                  >
                    <font-awesome-icon icon="cart-xmark" />
                    {{ $t('clearCarts') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="border-secondary" />
    </div>

    <WCSpinner v-if="!isFetched || isLoading" position="center" size="xl" />

    <!--Start Cart Drawer Items -->
    <div class="list-group list-group-flush wc-cart-drawer__wrapper flex-grow-1 pb-5">
      <template v-if="!isClearAllItemsSync && isFetched && !isLoading">
        <WCCartDrawerOrderTypeGroup
          v-if="hasCarts && !isScanAndGoMode"
          :carts="carts"
          :walletEbtTokens="ebtTokens"
          :loadingWallet="loadingWallet"
        />
        <WCCartDrawerOrderCard
          v-else-if="hasLineItems || isGiftCard"
          :cart="cart"
          :walletEbtTokens="ebtTokens"
          :loadingWallet="loadingWallet"
        />
        <WCEmptyCart v-else @close="close" />
      </template>

      <!--Start Cart Drawer Save For Later List -->
      <WCCartDrawerSaveForLaterList
        v-if="
          !isLoading &&
            isFetched &&
            detailList &&
            detailList.items &&
            detailList.items.length &&
            !isGiftCard &&
            !isGuest &&
            !isScanAndGoMode
        "
        :saveForLaterList="detailList"
      />
      <!--End Cart Drawer Save For Later List -->
    </div>
    <!-- End Cart Drawer Items -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import ModalService from '@/modules/modals/services/modal.service';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ScanAndGoMixin from '@/modules/user/mixins/ScanAndGoMixin';
import WCCartDrawerOrderTypeGroup from '@/modules/cart/components/WCCartDrawerOrderType/components/WCCartDrawerOrderTypeGroup.vue';
import WCCartDrawerEbtBalance from '@/modules/cart/components/WCCartDrawerEbtBalance/WCCartDrawerEbtBalance.vue';
import { PlatformMixin } from '@/modules/platform';
import WalletMixin from '@/views/Wallet/mixins/WalletMixin';
import WCEmptyCart from '../WCEmptyCart/WCEmptyCart.vue';
import WCCartDrawerSaveForLaterList from '../WCCartDrawerSaveForLaterList/WCCartDrawerSaveForLaterList.vue';
import WCClearCartModal from '../WCClearCartModal/WCClearCartModal.vue';
import WCSaveAllItemsForLaterModal from '../WCSaveAllItemsForLaterModal/WCSaveAllItemsForLaterModal.vue';
import WCCartDrawerOrderCard from '../WCCartDrawerOrderCard/WCCartDrawerOrderCard.vue';

// Clear all status for setting loader
const CLEAR_ALL_STATUS = {
  CLEAR_ALL_INIT: 'CLEAR_ALL_INIT',
  CLEAR_ALL_MODAL_OPEN: 'CLEAR_ALL_MODAL_OPEN',
  CLEAR_ALL_SYNC: 'CLEAR_ALL_SYNC',
  CLEAR_ALL_DONE: 'CLEAR_ALL_DONE',
};

export default {
  name: 'WCCartDrawer',
  props: {
    carts: {},
    lineItems: {
      required: false,
      type: Array,
    },
  },
  components: {
    WCEmptyCart,
    WCSpinner,
    WCCartDrawerSaveForLaterList,
    WCCartDrawerOrderTypeGroup,
    WCCartDrawerOrderCard,
    WCCartDrawerEbtBalance,
  },
  mixins: [WalletMixin, PlatformMixin, ScanAndGoMixin],
  data() {
    return {
      isClearAllItems: CLEAR_ALL_STATUS.CLEAR_ALL_INIT,
      loading: false,
      allCarts: false,
      loadingWallet: false,
    };
  },

  async mounted() {
    if (!this.isGuest && this.showEbtForCustomer) {
      this.loadingWallet = true;
      await this.fetchWallet()
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loadingWallet = false;
        });
    }
    await this.fetch();
    if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
      await this.getCarts({ loading: true });
    }
    if (this.isScanAndGoMode) {
      await this.reload();
    }
    if (!this.isGuest && !this.isGiftCard && (this.detailList || this.saveForLaterList)) {
      await this.loadDetails({ list: this.saveForLaterList });
    }

    if (this.$refs.checkoutButton) this.$refs.checkoutButton.$el.focus();
  },
  computed: {
    ...mapGetters({
      saveForLaterList: 'lists/getSaveForLaterList',
      isGuest: 'user/isGuest',
      isFetched: 'cart/isFetched',
      isGiftCard: 'cart/isGiftCard',
      getTotals: 'cart/getTotals',
      giftCardTotal: 'cart/getGiftCardTotals',
      detailList: 'lists/getDetailList',
      isLoading: 'cart/isLoading',
      isSyncing: 'cart/isSyncing',
      isScanAndGoMode: 'user/isCustomerModeScan',
      isCartsLoading: 'cart/cartsLoadingStatus',
      cart: 'cart/getCart',
    }),
    subTotal() {
      return this.getTotals && this.getTotals.sub;
    },
    cartTotalDisplay() {
      if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
        return this.getMultipleCartsTotal;
      }
      return this.giftCardTotal ? this.giftCardTotal : this.subTotal;
    },
    isClearAllItemsSync() {
      return this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_SYNC;
    },
    cartItems() {
      if (this.carts?.length && this.lineItems.length < 1) {
        return this.carts[0].lineItems;
      }
      return this.lineItems;
    },
    getMultipleCartsTotal() {
      let total = 0;
      if (this.carts && this.carts.length) {
        this.carts.forEach(c => {
          total += c.subTotal;
        });
      }

      return total;
    },
    hasLineItems() {
      return this.cartItems && this.cartItems.length;
    },
    hasCarts() {
      return this.$configuration.orderTypesEnabled && this.carts?.length;
    },
    ebtPaymentMethod() {
      return this.wallet?.ebtPaymentMethod;
    },
  },

  methods: {
    ...mapActions({
      loadDetails: 'lists/loadDetails',
      fetch: 'lists/fetch',
      reload: 'cart/reload',
      getCarts: 'cart/getCarts',
      setLoadingStatus: 'cart/setLoadingStatus',
      removeAllCartItems: 'cart/motRemoveAllCartItems',
    }),
    close() {
      this.emitter.$emit('cartDrawer:close');
    },
    clearAllItems() {
      this.removeAllCartItems();
    },
    clearAllCarts() {
      this.allCarts = true;
      return this.allCarts;
    },
    async openClearCartModal(clearCartBtnRef) {
      this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN;
      try {
        await ModalService.open(WCClearCartModal, {
          lineItems: this.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
          carts: this.carts,
          allCarts: this.allCarts,
        });
      } catch (error) {
        // NOOP
      } finally {
        if (clearCartBtnRef) clearCartBtnRef.focus();
      }
    },
    async openSaveAllItesForLaterModal(saveAllItesForLaterBtnRef) {
      this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN;
      try {
        await ModalService.open(WCSaveAllItemsForLaterModal, {
          lineItems: this.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
          carts: this.carts,
          allCarts: this.allCarts,
        });
      } catch (error) {
        // NOOP
      } finally {
        if (saveAllItesForLaterBtnRef) saveAllItesForLaterBtnRef.focus();
      }
    },
  },

  watch: {
    /**
     * Watcher to check isSyncing property for clear cart functionality and update loading status
     */
    isSyncing() {
      if (!this.isSyncing && this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_SYNC) {
        this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_DONE;
        if (!this.lineItems || this.lineItems?.length === 0 || !this.isGiftCard) {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'success',
              title: this.$t('clearCart'),
              message: this.$t('clearCartSuccess'),
            },
            timeout: 7000,
          });
        } else {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'danger',
              title: this.$t('clearCart'),
              message: this.$t('clearCartFailed'),
            },
            timeout: 7000,
          });
        }
      } else if (this.isSyncing && this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN) {
        this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_SYNC;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-cart-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 3rem;
  width: 40rem;
  max-width: 100%;
  z-index: map-get($zindex, 'overlays');
}

.cart-header {
  width: 100%;
  h4 {
    margin-right: 1rem;
  }
}

.wc-remove-hover {
  pointer-events: none;
}

.cart-spinner {
  min-height: 4rem;
}

.wc-medium-btn {
  display: block;
}

.wc-small-btn {
  display: none;
}

@include media-breakpoint-up(lg) {
  .wc-cart-drawer {
    bottom: rem(15px);
    top: rem(100px);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .cart-header {
    width: 100%;

    h4 {
      margin-right: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .wc-medium-btn {
    display: none;
  }

  .wc-small-btn {
    display: block;
  }
  .wc-cart-clear-save-btn-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.wc-subtotal {
  padding: $pad-2;
}

.wc-cart-drawer__wrapper {
  overflow-y: auto;
}
.wc-save-for-later__wrapper {
  overflow-y: auto;
}

.back-btn--width {
  width: rem(70px);
}

.wc-mobile-font {
  font-size: small;
}
</style>
