import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-374153b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row p-3 justify-content-between align-items-center" }
const _hoisted_2 = { class: "d-flex flex-row align-items-center" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCImage = _resolveComponent("WCImage")!
  const _component_WCCouponClipButton = _resolveComponent("WCCouponClipButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WCImage, {
        imageSrc: _ctx.coupon.imageUrl || '',
        imageAlt: _ctx.coupon.name,
        imageClass: "w-25 h-25 p-1",
        defaultClass: "w-25 h-25 p-1",
        defaultTitle: _ctx.coupon.name
      }, null, 8, ["imageSrc", "imageAlt", "defaultTitle"]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          "aria-label": `Coupon ${_ctx.coupon.name}`,
          "data-testid": "couponTitle",
          class: "btn link-dark underline-link-from-center ml-2 mr-2 font-size-xs",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleModalSwap && _ctx.handleModalSwap(...args)))
        }, _toDisplayString(_ctx.couponTitle), 9, _hoisted_4)
      ])
    ]),
    _createTextVNode(),
    _createVNode(_component_WCCouponClipButton, {
      class: "wc-coupon-card__bottom-button wc-coupon-card__bottom-button-detail",
      couponId: _ctx.coupon.id,
      availableLabel: _ctx.$t('clipCoupon'),
      clippedLabel: _ctx.$t('clippedCoupon'),
      redeemedLabel: _ctx.$t('couponRedeemed'),
      disabled: false,
      isClipped: _ctx.evaluateClipStatus,
      isRedeemed: _ctx.evaluateRedeemedStatus,
      handleClip: _ctx.handleClip
    }, null, 8, ["couponId", "availableLabel", "clippedLabel", "redeemedLabel", "isClipped", "isRedeemed", "handleClip"])
  ]))
}