import Alert from '@/models/entities/api/alert';

export default class ApiResponse {
  type: 'response';

  code: number;

  message: string;

  alerts?: Array<Alert>;

  constructor(source: unknown) {
    this.type = 'response';
    this.code = (source as any).code;
    this.message = (source as any).message;
    this.alerts = (source as any).alerts;
  }
}
