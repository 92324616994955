import CartResourceLock from '@/store/cart-resource-lock';
import RequestPromiseQueue from '@/store/request-promise-queue';
import ProtectedApiStateRequestTaskList, {
  REQUEST_TYPE,
  RequestType,
  TaskReference,
} from '@/store/protected-api-state-request-task-list';
import ApiResult, { isAlreadyHandled, isError } from '@/models/entities/api/api-result';
import ApiResponse from '@/models/entities/api/api-response';
import ApiAlreadyHandled from '@/models/entities/api/api-already-handled';

const sleep = async (ms: number) => {
  await new Promise(r => setTimeout(r, ms));
};

/**
 * Protects access to an API resource representing state synced with the front-end.
 * This means that when the front-end changes, the new state will be POST-ed to the back-end and
 * the front-end state will update based on the response. The front-end may also GET the current
 * state from time to time, for example at startup, when recovering from errors, or navigating
 * to new views.
 *
 * POSTs always take precedence over GETs. If a new POST request comes in while anything else is
 * processing, a new POST will be queued and all tasks continue to block until it resolves. If a
 * GET request comes in, it will only ever issue a request if there are currently no other
 * requests. Otherwise, it will block until the existing request finishes.
 *
 * Only one request will return the response or error. All others will return ApiAlreadyHandled.
 * This resource is therefore only suitable for applications than handle all responses the same
 * way, in one place. If there is a need to handle different requests differently, employ a
 * different solution.
 */
export default class ProtectedApiStateResource<T extends ApiResponse> {
  private readonly resource: string;

  private resourceLock: CartResourceLock;

  private promiseQueue: RequestPromiseQueue<ApiResult<T>>;

  private taskList: ProtectedApiStateRequestTaskList;

  private taskCount: number;

  private cachedResult?: ApiResult<T>;

  private readonly doGetRequest: () => Promise<ApiResult<T>>;

  private readonly doPostRequest: () => Promise<ApiResult<T>>;

  constructor(
    resource: string,
    resourceLock: CartResourceLock,
    doGetRequest: () => Promise<ApiResult<T>>,
    doPostRequest: () => Promise<ApiResult<T>>,
  ) {
    this.resource = resource;
    this.resourceLock = resourceLock;
    this.doGetRequest = doGetRequest;
    this.doPostRequest = doPostRequest;
    this.promiseQueue = new RequestPromiseQueue();
    this.taskList = new ProtectedApiStateRequestTaskList();
    this.taskCount = 0;
  }

  async get() {
    await this.scheduleRequest(REQUEST_TYPE.GET, 0);
    return this.getResult();
  }

  async post(delayMs: number) {
    await this.scheduleRequest(REQUEST_TYPE.POST, delayMs);
    return this.getResult();
  }

  private async scheduleRequest(type: RequestType, delayMs: number) {
    await this.resourceLock.take(this.resource);

    const task = await this.initializeTask(type, delayMs);

    await this.processPromiseQueue(task);

    console.debug(`Task ${task.id} done`);
    this.taskList.remove(task);
  }

  private async initializeTask(type: RequestType, delayMs: number): Promise<TaskReference> {
    this.taskCount += 1;
    const taskId = this.taskCount;
    console.debug(`Initializing task ${taskId} (${type})`);
    const taskReference = this.taskList.add(taskId, type, delayMs);
    await this.waitForStart(taskReference);
    if (!this.promiseQueue.length()) {
      this.addToPromiseQueue();
    }
    return taskReference;
  }

  private async waitForStart(task: TaskReference) {
    let isAllowedToStart: boolean;
    let delayMs: number;
    ({ isAllowedToStart, delayMs } = this.taskList.isAllowedToStart());
    while (!isAllowedToStart) {
      console.debug(`Not ready to start, sleeping for ${delayMs}ms (${task.id})`);
      // eslint-disable-next-line no-await-in-loop
      await sleep(delayMs);
      ({ isAllowedToStart, delayMs } = this.taskList.isAllowedToStart());
    }
    console.debug(`Ready to start (${task.id})`);
  }

  private addToPromiseQueue() {
    const mostRelevantTaskWhenStarted = this.taskList.mostRelevantTask();
    const mostRelevantTaskIdWhenStarted = mostRelevantTaskWhenStarted.id;
    const mostRelevantTaskTypeWhenStarted = mostRelevantTaskWhenStarted.type;
    if (mostRelevantTaskTypeWhenStarted === REQUEST_TYPE.POST) {
      this.promiseQueue.push({ promise: this.doPostRequest(), mostRelevantTaskIdWhenStarted });
    } else {
      this.promiseQueue.push({ promise: this.doGetRequest(), mostRelevantTaskIdWhenStarted });
    }
  }

  private async processPromiseQueue(task: TaskReference) {
    let currentPromiseIndex = 0;
    while (currentPromiseIndex < this.promiseQueue.length()) {
      const { promise, mostRelevantTaskIdWhenStarted } = this.promiseQueue.get(currentPromiseIndex);
      console.debug(`Waiting on promise (${task.id} / ${mostRelevantTaskIdWhenStarted})`);
      // eslint-disable-next-line no-await-in-loop
      const result = await promise;

      if (this.cachedResult) {
        console.debug(
          `Already cached, nothing to do (${task.id} / ${mostRelevantTaskIdWhenStarted})`,
        );
      } else if (
        this.requiresImmediateHandling(result) ||
        this.taskList.mostRelevantTask().id === mostRelevantTaskIdWhenStarted
      ) {
        console.debug(`Caching response (${task.id} / ${mostRelevantTaskIdWhenStarted})`);
        this.cachedResult = result;
      } else if (currentPromiseIndex === this.promiseQueue.length() - 1) {
        console.debug(
          `Reached end of queue without finding suitable response, adding another promise (${task.id} / ${mostRelevantTaskIdWhenStarted})`,
        );
        this.addToPromiseQueue();
      }
      currentPromiseIndex += 1;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private requiresImmediateHandling(result: ApiResult<T>) {
    if (isError(result) || isAlreadyHandled(result)) {
      return true;
    }
    return result.alerts?.length;
  }

  private async getResult() {
    if (!this.cachedResult) {
      return new ApiAlreadyHandled();
    }
    const result = this.cachedResult;
    this.cachedResult = null;
    await this.taskList.waitForAllToFinish();
    this.taskList.clear();
    this.promiseQueue.clear();
    this.taskCount = 0;
    this.resourceLock.release(this.resource);
    return result;
  }
}
