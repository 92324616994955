import ProtectedApiStateResource from '@/store/protected-api-state-resource';
import ApiResult from '@/models/entities/api/api-result';
import CartResponse from '@/models/entities/cart/cart-response';
import CartResourceLock from '@/store/cart-resource-lock';
import { getApiErrorFromResponse, makeApiError } from '@/models/entities/api/api-error';
import { RESPONSE_CODE } from '@/constants/ApiConstants';
import CartRequest from '@/models/entities/cart/cart-request';
import axios from 'axios';

export default class CartResource {
  private protectedApiStateResource: ProtectedApiStateResource<CartResponse>;

  private httpRequestStartedHook: () => void;

  private clientCartGetter: () => Promise<CartRequest>;

  private readonly url: string;

  private orderTypeId?: string;

  private lastSyncTime: string;

  constructor(cartResourceLock: CartResourceLock, resource: string, url: string) {
    this.protectedApiStateResource = new ProtectedApiStateResource<CartResponse>(
      resource,
      cartResourceLock,
      async () => {
        return this.getCart();
      },
      async () => {
        return this.syncCart();
      },
    );
    this.httpRequestStartedHook = null;
    this.url = url;
    this.clientCartGetter = null;
    this.orderTypeId = null;
    this.lastSyncTime = null;
  }

  setHttpRequestStartedHook(httpRequestStartedHook: () => void) {
    this.httpRequestStartedHook = httpRequestStartedHook;
  }

  setClientCartGetter(clientCartGetter: () => Promise<CartRequest>) {
    this.clientCartGetter = clientCartGetter;
  }

  async get() {
    return this.protectedApiStateResource.get();
  }

  async post(delayMs: number) {
    return this.protectedApiStateResource.post(delayMs);
  }

  getLastSyncTime() {
    return this.lastSyncTime;
  }

  setLastSyncTime(lastSyncTime: string) {
    this.lastSyncTime = lastSyncTime;
  }

  getOrderTypeId() {
    return this.orderTypeId;
  }

  setOrderTypeId(orderTypeId: string) {
    this.orderTypeId = orderTypeId;
  }

  private async getCart() {
    let axiosResponse: any;
    try {
      axiosResponse = await axios.get(this.url);
    } catch (error) {
      console.error(error);
      return getApiErrorFromResponse(error);
    }

    return this.handleAxiosResponse(axiosResponse);
  }

  private async syncCart() {
    let result: ApiResult<CartResponse> = null;
    if (!this.clientCartGetter) {
      console.error('clientCartGetter is missing, but it is required for post');
      result = makeApiError(RESPONSE_CODE.CODE_GENERIC_ERROR, 'System failure');
      return result;
    }
    console.debug('Start syncCart');
    const cart = await this.clientCartGetter();
    cart.lastSyncTime = this.lastSyncTime;

    this.callHttpLoadingStartedHook();
    let axiosResponse: any;
    try {
      axiosResponse = await axios.post(this.url, cart);
    } catch (error) {
      console.error(error);
      return getApiErrorFromResponse(error);
    }

    return this.handleAxiosResponse(axiosResponse);
  }

  private handleAxiosResponse(axiosResponse: any) {
    let result: ApiResult<CartResponse>;
    try {
      result = new CartResponse(axiosResponse.data);
      this.lastSyncTime = result.lastSyncTime;
      this.orderTypeId = result.orderType;
    } catch (error) {
      console.error(error);
      result = makeApiError(RESPONSE_CODE.CODE_GENERIC_ERROR, 'Server returned incompatible data');
    }
    return result;
  }

  private callHttpLoadingStartedHook() {
    if (this.httpRequestStartedHook) {
      this.httpRequestStartedHook();
    }
  }
}
