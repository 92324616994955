import CartResourceLock from '@/store/cart-resource-lock';
import ApiResponse from '@/models/entities/api/api-response';
import ApiResult from '@/models/entities/api/api-result';

/**
 * Protects access to an API resource representing an action that can be taken.
 *
 * If a POST request comes in while one is already running, it will block until the running request
 * resolves.
 *
 * Only one request will return the response or error. All others will return ApiAlreadyHandled.
 * This resource is therefore only suitable for applications that handle all responses the same
 * way, in one place. If there is a need to handle different requests differently, employ a
 * different solution.
 */
export default class ProtectedApiActionResource<T extends ApiResponse> {
  private readonly resource: string;

  private readonly resourceLock: CartResourceLock;

  private readonly doPostRequest: () => Promise<ApiResult<T>>;

  private promise?: Promise<ApiResult<T>>;

  constructor(
    resource: string,
    resourceLock: CartResourceLock,
    doPostRequest: () => Promise<ApiResult<T>>,
  ) {
    this.resource = resource;
    this.resourceLock = resourceLock;
    this.doPostRequest = doPostRequest;
    this.promise = null;
  }

  async post() {
    await this.resourceLock.take(this.resource);
    if (this.promise === null) {
      this.promise = this.doPostRequest();
    }
    const result = await this.promise;
    this.promise = null;
    this.resourceLock.release(this.resource);
    return result;
  }
}
