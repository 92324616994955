
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { RESPONSE_CODE } from '@/constants/ApiConstants';
import ToastMixin from '../../mixins/ToastMixin';
import WCToast from '../WCToast/WCToast.vue';
import WCToastHeader from '../WCToastHeader/WCToastHeader.vue';
import WCToastBody from '../WCToastBody/WCToastBody.vue';

export default {
  name: 'WCSyncErrorToast',
  mixins: [ToastMixin],
  components: { FontAwesomeIcon, WCToast, WCToastHeader, WCToastBody },
  props: {
    errorCode: {
      type: Number,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  computed: {
    message() {
      if (this.errorCode === RESPONSE_CODE.CODE_CART_OUTDATED) {
        return this.$t('cartOutdatedMessage');
      }
      if (this.errorMessage) {
        return this.errorMessage;
      }
      return this.$t('error');
    },
  },
};
