export type RequestPromise<T> = {
  promise: Promise<T>;
  mostRelevantTaskIdWhenStarted: number;
};

export default class RequestPromiseQueue<T> {
  private queue: Array<RequestPromise<T>>;

  constructor() {
    this.queue = [];
  }

  length() {
    return this.queue.length;
  }

  push(requestPromise: RequestPromise<T>) {
    this.queue.push(requestPromise);
  }

  get(index: number) {
    return this.queue[index];
  }

  clear() {
    this.queue = [];
  }
}
