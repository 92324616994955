import ApiError from '@/models/entities/api/api-error';
import ApiAlreadyHandled from '@/models/entities/api/api-already-handled';
import ApiResponse from '@/models/entities/api/api-response';

type ApiResult<T extends ApiResponse> = T | ApiError | ApiAlreadyHandled;
export default ApiResult;

export function isResponse<T extends ApiResponse>(result: ApiResult<T>): result is T {
  return result.type === 'response';
}

export function isError<T extends ApiResponse>(result: ApiResult<T>): result is ApiError {
  return result.type === 'error';
}

export function isAlreadyHandled<T extends ApiResponse>(
  result: ApiResult<T>,
): result is ApiAlreadyHandled {
  return result.type === 'already_handled';
}
