export const RESOURCE = {
  ACTIVE_CART: 'ACTIVE_CART',
  ORDER_TYPE_CART: (id: string) => `ORDER_TYPE_CART_${id}`,
  CONFIRM: 'CONFIRM',
  ALL_CARTS: 'ALL_CARTS',
};

export default class CartResourceLock {
  private currentResource?: string;

  private lock: Promise<void>;

  private releaseLock: Function;

  constructor() {
    this.currentResource = null;
    this.lock = new Promise(resolve => {
      this.releaseLock = resolve;
    });
    this.releaseLock();
  }

  async take(resource: string) {
    console.debug(`Requesting to take ${resource}`);
    if (this.currentResource === resource) {
      console.debug(`Already holding ${resource}`);
      return;
    }
    while (this.currentResource !== null) {
      console.debug(`Waiting for resource lock. Currently holding ${this.currentResource}`);
      // eslint-disable-next-line no-await-in-loop
      await this.lock;
      if (this.currentResource === resource) {
        console.debug("Another task obtained the lock, that's good enough!");
        return;
      }
    }
    console.debug(`Obtained resource lock for ${resource}`);
    this.currentResource = resource;
    this.lock = new Promise(resolve => {
      this.releaseLock = resolve;
    });
  }

  release(resource: string) {
    if (this.currentResource !== resource) {
      console.debug(`Lock already released for ${resource}`);
      return;
    }
    console.debug(`Releasing resource lock for ${resource}`);
    this.currentResource = null;
    this.releaseLock();
  }
}
