import OrderTypeCartResource from '@/store/order-type-cart-resource';
import CartResourceLock from '@/store/cart-resource-lock';

export default class OrderTypeCartResourceMap {
  private readonly map: Record<string, OrderTypeCartResource>;

  private readonly resourceLock: CartResourceLock;

  constructor(resourceLock: CartResourceLock) {
    this.map = {};
    this.resourceLock = resourceLock;
  }

  get(orderTypeId: string) {
    if (!this.map[orderTypeId]) {
      this.map[orderTypeId] = new OrderTypeCartResource(this.resourceLock, orderTypeId);
    }
    return this.map[orderTypeId];
  }

  getAll() {
    return Object.values(this.map);
  }
}
